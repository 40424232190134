import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Home from "../components/home"

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Gmail2ECM"
        keywords={[`gmail2ecm`, `gmail ecm`, `gmailecm`, `email ecm`,`email to ecm`]}
      />

      <Home></Home>
    </Layout>
  );
}

export default IndexPage;
