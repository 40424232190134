import React from 'react'
import enterpriseTeamVector from "../images/enterprise_pic.png"
import picSection1 from "../images/picSection1.png"
import marketPlaceIcon from "../images/g_workspace_markt.png"
import prodBoost from "../images/start-up.png"
import { navigate } from "gatsby"  

export default class Home extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            loadAnimation:false,
            bridgeTypeAnimation:false,
            customizeNeedsAnimation:false
        }
    }

    componentDidMount(){
        //mounting braucht einige Millisekunden, setTimeout nicht nötig 
        this.setState({loadAnimation:true})
        this.addScrollListeners()
    }

    componentWillUnmount(){
        document.removeEventListener('scroll',this.trackScrolling)
    }

    addScrollListeners(){
        this.trackScrolling = this.trackScrolling.bind(this)
        document.addEventListener('scroll',this.trackScrolling)
    }

    trackScrolling(){
        const bridgeTypeAnimation        = this.state.bridgeTypeAnimation        
        const customizeNeedsAnimation    = this.state.customizeNeedsAnimation
        const bridgeTypeID               = "bridgeTypes"
        const customizeNeedsID           = "customizeNeeds"
        const displayNameBridgeType      = Object.keys({bridgeTypeAnimation})[0]         //Name der Variable in Klartext : bridgeTypeAnimation
        const displayNameCustomizedNeeds = Object.keys({customizeNeedsAnimation})[0]     //Name der Variable in Klartext : customizeNeedsAnimation

        this.handleScrollAnimation(bridgeTypeID     ,displayNameBridgeType)
        this.handleScrollAnimation(customizeNeedsID ,displayNameCustomizedNeeds)
    }

    handleScrollAnimation(elementID,stateName){
        let elementPositionY = 
            document.getElementById(String(elementID))
                    .getBoundingClientRect()
                    .top

        if (elementPositionY <= window.innerHeight && !this.state[stateName]){
            this.setState({
                [stateName]:true
            })
        }
    }

    navigateToContact() {
        navigate("/contact")
    }
    
    render(){
        return (
        <div className="gradient">
             {/* Title Section */}
            <div className="pt-24 px-4" id="titleSection">
                {this.state.loadAnimation &&
                    <div className="container fadeInUpOnAppear max-w-4xl mx-auto flex flex-wrap flex-col md:flex-row items-center">
                        <div className="flex flex-col w-full md:w-1/2 justify-center items-start text-left md:text-left">
                            <h1 className="my-4 text-4xl font-bold leading-tight">Gmail2ECM</h1>
                            <p className="leading-normal text-xl mb-8">
                                A solution for storing your Gmail messages to an ECM / Archiving platform.
                            </p>

                            <button onClick={this.navigateToContact}
                                    className="mx-auto lg:mx-0 buttonHover bg-white text-gray-800 font-bold rounded-full my-2 py-2 px-6 shadow-lg">
                                <div className="flex flex-row">
                                    <div className="text-lg">
                                    Add to your Gmail
                                    </div>
                                    <img className="h-8 ml-4" src={marketPlaceIcon}></img>
                                </div>
                            </button>

                        </div>
                        <div className="md:w-1/2 mt-10 text-center pl-32">
                            <img className="" src={enterpriseTeamVector} />
                        </div>
                    </div>
                }
            </div>
           

            {/* Waves SVG */}
            <div>
                <svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fillRule="nonzero">
                            <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
                            <path d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z" opacity="0.100000001"></path>
                            <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" id="Path-4" opacity="0.200000003"></path>
                        </g>
                        <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fillRule="nonzero">
                            <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
                        </g>
                    </g>
                </svg>
            </div>

            {/* Gmail Connect und boost prod */}
            <section className="bg-white border-b py-8">
                <div className="container max-w-5xl mx-auto m-8">
                    <h2 className="w-full my-2 text-4xl font-bold leading-tight text-center text-gray-800">Gmail – connected to ECM</h2>
                    <div className="w-full mb-4">	
                        <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0"></div>
                    </div>

                    <div className="w-full flex flex-wrap flex-col sm:flex-row justify-center">
                        <div className="flex-1 sm:w-full p-6">
                            <p className=" text-gray-600 mb-8 text-xl text-left">
                                Although over the past decade, social media and chat  has changed the way we communicate, 
                                email still remains the most  important  business communication tool. 
                                Moreover, it is the preferred method of communicating with customers. 
                                Due to its simplicity Google´s   Gmail is becoming more and more important as a business mail solution. 
                                Unfortunately, most of the ECM solutions lack integrations to store Gmail messages in the ECM / Archiving platform. 
                            </p>
                        </div>
                        <img src={picSection1} className="h-64 w-64 ml-10"/>
                    </div>
                </div>

                <div className="container max-w-5xl mx-auto m-8 mt-16">
                    <h2 className="w-full my-2 text-4xl font-bold leading-tight text-center text-gray-800">Boost your Productivity</h2>
                    <div className="w-full mb-4">	
                        <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                    </div>

                    <div className="w-full flex flex-wrap flex-col sm:flex-row justify-center md:flex-row">
                        <img src={prodBoost} className="h-64 w-64"/>
                        <div className="flex-1 w-full sm:w-1/2 p-6">
                            <p className=" text-gray-600 mb-8 mt ml-10 text-xl text-right">
                                When emails are stored in the users mailbox, nobody in the enterprise has a clear picture of a specific case. 
                                Currently, it is cumbersome to export mails and their attachments from Gmail to import them into the ECM Solution. 
                                <br></br>
                                <br></br>
                                With the <b>GMAIL2ECM  Adapter</b>, users can simply store their mails into the ECM system by just a click.  
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            {/* ECM Bridge Types */}
            <section className="bg-white pt-8" id="bridgeTypes">
                {this.state.bridgeTypeAnimation &&
                    <div className="container fadeInUpOnAppear max-w-4xl mx-auto flex flex-wrap pt-4">
                        <h2 className="w-full my-2 text-4xl font-bold leading-tight text-center text-gray-800">Choose the way you work.</h2>
                        <div className="w-full mb-4">	
                            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                            <p className="text-gray-600 mb-8 mt-6 text-xl text-center">
                                Currently we offer 2 types of ECM bridges:
                            </p>
                        </div>

                        <div className="flex lg:flex-row flex-col justify-items-center m-4 p-2">
                            <div className="w-full xl:w-1/2 mt-2 mr-4 flex flex-col shadow-2xl gradient myRounded">
                                <div className="h-full flex flex-wrap p-2 text-center overflow-hidden ">
                                    <div className="w-full mt-4 text-3xl font-bold text-center text-gray-800">Label based ECM&nbsp;Bridge</div>
                                    <p className="w-full text-gray-800 text-xl text-center px-2 py-2">
                                        By simply adding a Gmail- label to a mail, the mail is transferred to the ECM System.  
                                        <br></br><br></br>
                                        <b>
                                        Great advantage: No open ports
                                        </b>
                                    </p>
                                </div>
                            </div>

                            <div className= "w-full xl:w-1/2 mt-2 flex flex-col shadow-2xl gradient myRounded">
                                <div className="h-full flex flex-wrap p-2 text-center overflow-hidden ">
                                    <div className="w-full mt-2 text-3xl font-bold text-center text-gray-800">Side Pane based ECM&nbsp;Bridge</div>
                                    <p className="w-full text-gray-800 text-xl text-center px-2 mb-5">
                                        A sidepane offers more interaction for the user
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </section>
           
            

            <section className="bg-white border-b py-8 pb-16 px-3" id="connectPDF">
                {this.state.bridgeTypeAnimation &&
                    <div className="fadeInUpOnAppear">
                        <div className="container lg:max-w-4xl mx-auto m-8">
                            <h2 className="w-full my-2 text-4xl font-bold leading-tight text-center text-gray-800">Connected to ECM</h2>
                            <div className="w-full mb-4">	
                                <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                            </div>

                            <p className="text-gray-600 mb-8 text-xl text-center">
                            We have successfully connected  several ECM Systems to GMAIL. 
                            In general all ECM systems from vendors such as <b>OpenText, SER, Hyland, ELO or EASY</b> can be connected. 
                            Based on the API technology and their licensing, the customer has to   
                            provide the API and its proper licensing. – <b></b>The rest comes from us.
                            </p>
                        </div>

                        <div className="container max-w-4xl mx-auto m-8 mt-16">
                        <h2 className="w-full my-2 text-4xl font-bold leading-tight text-center text-gray-800">Rendering to PDF</h2>
                        <div className="w-full mb-4">	
                            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                        </div>                        
                            <p className="text-gray-600 mb-8 mt text-xl text-center">
                            Additionally we are able to render the Mail to a PDF document. 
                            The Attachments are embedded into the document so that the E-Mail can be viewed without any problems in many years.   
                            </p>
                        </div>
                    </div>
                }
            </section>




            {/* Waves SVG */}
            <svg className="wave-top" viewBox="0 0 1439 147" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-1.000000, -14.000000)" fillRule="nonzero">
                        <g className="wave" fill="#f8fafc">
                        <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
                        </g>
                        <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
                        <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                            <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
                            <path d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z" opacity="0.100000001"></path>
                            <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" opacity="0.200000003"></path>
                        </g>
                        </g>
                    </g>
                </g>
            </svg>

            {/* CTA block */}
            <section className="container mx-auto text-center py-10 max-w-4/5" id="customizeNeeds">
                <div className={this.state.customizeNeedsAnimation?"fadeInOnApper m-8":"invisible m-8"}>
                    <h1 className="w-full my-2 text-4xl font-bold leading-tight text-center text-white">Customize your needs</h1>
                    <div className="w-full mb-4">	
                        <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
                    </div>
                    <h3 className="my-8 text-2xl leading-tight">Our professional services team can extend the standard solution to your specific needs. </h3>	
                    <button onClick={this.navigateToContact}
                            className="mx-auto lg:mx-0 buttonHover bg-white text-gray-800 font-bold rounded-full my-2 py-2 px-6 shadow-lg">
                        <div className="flex flex-row">
                            <div className="text-lg">
                            Contact Us Now
                            </div>
                        </div>
                    </button>
                </div>
            </section>
        </div>
        )
    }
}
